<template>
    <div class="col-12">
        <div class="card">
            <h5>Credencial</h5>
            
        </div>
    </div>

</template>

<script lang="js">
    export default {
        name: 'Credencial', 
        data() {
           
        },
       
    }
    
</script>