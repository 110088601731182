<template>
    <Credencial />
</template>

<script lang="js">
    
    import Credencial from '../../components/consulta/Credencial.vue'

    export  default {
        components: { Credencial }
    }

</script>